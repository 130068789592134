.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.css-h4y409-MuiList-root{height: 100vh!important;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-padding td , .table-padding th{padding: 1rem .5rem!important;}

.MuiDrawer-docked{min-height: 100vh!important;}
@media (min-width: 0px){
  .css-13420go {
      display: flex!important;
  }}
  @media (max-width: 600px){
    .MuiListItemIcon-root {
    padding-left: 0!important;
  }
  .MuiDrawer-paper{width: 70px!important;}
  
  tbody, td, tfoot, th, thead, tr {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
  
  }
  .hover-btn{ top: -10px;
    z-index: 999;
    position: absolute !important;
    right: -10px;
    background-color: #2976d2 !important;
    color: white !important;}
.no-list ul{padding: 0px;list-style: none!important}
.no-list ul li::marker{list-style: none!important;}

.ql-align-center {
  text-align: center;
}
.ql-editor h1 {
  font-size: 18px!important;
  margin-bottom: 30px!important;
}

.ql-editor p,.ql-editor ul {
  margin-bottom: 30px!important;
  font-weight: 100!important;
  font-size: 13px!important;
  line-height: 1.5!important;
  font-family: 'Futura', sans-serif!important;
  letter-spacing: 0.02em!important;
}

textarea, input{font-size: 13px!important;}
.css-17u83us-MuiButtonBase-root-MuiButton-root{    color: #fff!important;
  background: #1976d2!important;
  margin-bottom: 13px!important;}
.bg-darklight {
    background-color: rgb(237 237 237) !important;
}
.button-small {
  padding: 5px 10px !important;
  font-size: 12px !important;
}